:root {
  --accent-color: #9177A6;
  --background-color:white;
  --shadow-color: #f8f8f8;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 20px;
  text-rendering: optimizeLegibility;

  padding: 0 8px;
}

body.dragging {
  background-color: #CABDD4;
}

#root {
  max-width: 500px;
  margin: 0 auto 0 auto;
  padding-top: 30vh;
}

p {
  padding: 0 5vw;
  font-size: 1em;
}

button {
  border: none;
  background: transparent;
  vertical-align: middle;
}

.card {
  display: grid;
  grid-template-columns: 15% auto 30px;
  grid-template-areas: "lead title cancel";
  width: 100%;
  box-sizing: border-box;
  margin: 1em 0;
  padding: 0.5em;

  background: linear-gradient(180deg, #F8F8F8 0%, #F8F8F8 100%);
  box-shadow: 0px 4px 5px -3px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.card > img {
  grid-area: lead;
  margin-left: 50%;
}

label.card, .card > input {
  cursor: pointer;
}

.card button {
  cursor: pointer;
}

.card > input {
  grid-area: title;
  font-size: 0.8em;
  vertical-align: bottom;
  padding-left: 0.5em;
}

.card-file {
  grid-template-rows: 50% 50%;
  grid-template-areas: "lead title cancel"
                       "follow subtitle subtitle";
  grid-gap: 2px 8px;
}

.card input {
  /* Safari seems to need this to keep the input from causing horizontal scroll. */
  width: 100%;
  align-self: center;
}

.card-lead {
  grid-area: lead;
  color: var(--accent-color);
  text-align: right;
}

.card-title {
  grid-area: title;
}

.card-follow {
  grid-area: follow;
  color: #aaa;
  font-size: 0.7em;
  text-align: right;
  vertical-align: bottom;
  align-self: end;
}

.card-subtitle {
  grid-area: subtitle;
  align-self: end;
  font-size: 0.8em;
  color: #aaa;
}

.card-progress-bar {
  grid-area: subtitle;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 3px;

  font-size: 0.7em;
  color: #aaa;
  text-align: right;
  align-self: end;
  margin-bottom: 2px;
}

.card-progress-complete {
  background: #CABDD4;
  border-radius: 3px;
  height: 10px;
}

.card-cancel {
  grid-area: cancel;
  align-self: start;
}
