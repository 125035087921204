:root {
  --accent-color: #9177a6;
  --background-color: white;
  --shadow-color: #f8f8f8;
}

body {
  text-rendering: optimizelegibility;
  padding: 0 8px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 20px;
}

body.dragging {
  background-color: #cabdd4;
}

#root {
  max-width: 500px;
  margin: 0 auto;
  padding-top: 30vh;
}

p {
  padding: 0 5vw;
  font-size: 1em;
}

button {
  vertical-align: middle;
  background: none;
  border: none;
}

.card {
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(#f8f8f8 0% 100%);
  border-radius: 8px;
  grid-template-columns: 15% auto 30px;
  grid-template-areas: "lead title cancel";
  margin: 1em 0;
  padding: .5em;
  display: grid;
  box-shadow: 0 4px 5px -3px #0000001f;
}

.card > img {
  grid-area: lead;
  margin-left: 50%;
}

label.card, .card > input, .card button {
  cursor: pointer;
}

.card > input {
  vertical-align: bottom;
  grid-area: title;
  padding-left: .5em;
  font-size: .8em;
}

.card-file {
  grid-gap: 2px 8px;
  grid-template-rows: 50% 50%;
  grid-template-areas: "lead title cancel"
                       "follow subtitle subtitle";
}

.card input {
  width: 100%;
  align-self: center;
}

.card-lead {
  color: var(--accent-color);
  text-align: right;
  grid-area: lead;
}

.card-title {
  grid-area: title;
}

.card-follow {
  color: #aaa;
  text-align: right;
  vertical-align: bottom;
  grid-area: follow;
  align-self: end;
  font-size: .7em;
}

.card-subtitle {
  color: #aaa;
  grid-area: subtitle;
  align-self: end;
  font-size: .8em;
}

.card-progress-bar {
  box-sizing: border-box;
  color: #aaa;
  text-align: right;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  grid-area: subtitle;
  align-self: end;
  margin-bottom: 2px;
  font-size: .7em;
}

.card-progress-complete {
  height: 10px;
  background: #cabdd4;
  border-radius: 3px;
}

.card-cancel {
  grid-area: cancel;
  align-self: start;
}

/*# sourceMappingURL=index.56c40f2b.css.map */
